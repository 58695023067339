import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { IonicModule } from "@ionic/angular";
import { AppRoutingModule } from "./app-routing.module";
import { DatePipe } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { RouteReuseStrategy } from "@angular/router";
import { IonicRouteStrategy } from "@ionic/angular/standalone";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    DatePipe,
    FormBuilder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => { },
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
