import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor() {
    this.checkInitialLoginState();
  }

  public checkInitialLoginState() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setLoginState(true);
      } else {
        this.setLoginState(false);
      }
    });
  }

  setLoginState(isLoggedIn: boolean) {
    this.isLoggedInSubject.next(isLoggedIn);
  }
}
