import { Component } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { UserStateService } from './services/user-state.service';

const firebaseConfig = {
  apiKey: "AIzaSyC2K2tJP4ONEYBByA8a2NfCpVkIBTjEGJI",
  authDomain: "dinosaurweb-1a9ed.firebaseapp.com",
  projectId: "dinosaurweb-1a9ed",
  storageBucket: "dinosaurweb-1a9ed.appspot.com",
  messagingSenderId: "773901921658",
  appId: "1:773901921658:web:1998ba18882355c82cbac3"
};

const app = initializeApp(firebaseConfig);

if (app) {
  console.log('Firebase initialized successfully!');
} else {
  console.error('Error initializing Firebase (app instance is null)');
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private userStateService: UserStateService
  ) {
    this.userStateService.checkInitialLoginState();
  }
}
